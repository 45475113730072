/**
 * Validator for Nelonen campaigns.
 */
(function($){

    /**
     * Adds sanitize function to jquery elements which returns the sanitized value of $.fn.val().
     */
    var div = document.createElement('div');
    $.fn.sanitize = function(){
        div.innerHTML = this.val();
        var result = div.textContent || div.innerText || '';
        div.innerHTML = '';
        return result.trim();
    };

    var formDefaultMessage = 'Korjaa virheelliset kentät.';

    // The available validators
    $.validators = {
        required: {
            validator: function(field){
                if(field.attr('type') === 'checkbox'){
                    return field.get(0).checked;
                }

                if(field.attr('type') === 'radio'){

                    if ( $('input[name="'+ field.get(0).name +'"]:checked').length ) {
                        return true;
                    } else {
                        return false;
                    }

                }

                return field.sanitize() !== '';
            },
            defaultMessage: ''
        },
        email: {
            validator: function(field){
                var value = field.sanitize();
                var re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$');
                return value.length > 0 && re.test(value);
            },
            defaultMessage: 'Sähköposti on virheellinen.'
        },
        number: {
            validator: function(field){
                var value = field.sanitize();
                var re = new RegExp('^[0-9]{1,}$');
                return value.length > 0 && re.test(value);
            },
            defaultMessage: 'Virheellinen numero.'
        },
        positiveNumber: {
            validator: function(field){
                var value = $.validators.number.validator(field);

                if(value){
                    return field.sanitize() >= 0;
                }
                return value;
            },
            defaultMessage: 'Ikä ei voi olla negatiivinen.'
        }
    };


    /**
     * Function which is used to validate the jquery form.
     *
     * Example usage:
     *  $('#myform').validateForm({
	 *		age: {
	 *			required: '',
	 *			number: 'Iän on oltava numero.'
	 *		},
	 *	});
     *
     * The above example would validate the field which has name="age" attribute.
     * The validation fails if field is empty or if the value in field is not a number.
     * The error message is the default one, if empty string is given as parameter.
     * Eg. The "required" validator uses the default error message, but "number" validator uses the given error message.
     *
     * @param data - The object which contains the name of input/textarea fields which should be validated and the used validators.
     * @return True if validation was successfull, false otherwise.
     */
    $.fn.validateForm = function(data){
        var errors = this.validateFields(data);
        this.trigger('validated', [errors, data]);

        // Reset form validation state
        this.find('.input-wrapper').removeClass('error').find('.validation-message').html('').hide();
        this.find('.form-message').val('').hide();
        this.removeClass('error');

        if(Object.keys(errors).length === 0){
            return true;
        }

        this.addClass('error');

        // Set field error messages
        for(var field in errors){
            var msg = [];
            for(var validator in errors[field]){
                msg.push(errors[field][validator]);
            }

            this.find('[name="' + field + '"]').closest('.input-wrapper').addClass('error').find('.validation-message').html(msg[0]).show();
        }

        this.find('.form-message').html(data.formValidationMessage ? data.formValidationMessage : formDefaultMessage).show();

        return false;
    };

    $.fn.validateFields = function(data){
        var errors = {};

        this.find('input, textarea').each(function(){
            var self = $(this);
            var validators = data[self.attr('name')];

            if(validators === undefined){
                return true;
            }

            for(var validator in validators){
                if($.validators[validator] !== undefined){
                    var isValid = $.validators[validator].validator(self);

                    if(!isValid){
                        var msg = validators[validator];
                        if(!msg){
                            msg = $.validators[validator].defaultMessage;
                        }

                        if(typeof errors[self.attr('name')] === 'undefined'){
                            errors[self.attr('name')] = {};
                        }

                        errors[self.attr('name')][validator] = msg;
                    }
                }
            }
        });

        return errors;
    };

})(jQuery);